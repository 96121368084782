
.input::placeholder {
    color:#BCC6D9 !important;
}

.input:focus {
    background-color: #FFFFFF !important;
    border: 1px solid #BCC6D9 !important;
}

/* .input {} */
